import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import ClientCard from "../ClientCard/ClientCard"
import ReactPaginate from 'react-paginate'
import uuidv4 from "uuid";
import ClientsAPI from '../../../api/clientsAPI'
import './style.css'
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap";

class Clients extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cliets: [],
      page: 1,
      limit: 12,
      total: 0,
      popupOpen: false,
      delayTimer: '',
      errorMsg: false,
      imageName: '',
      subscriptionStatus: '',
    };
  }

  async componentDidMount() {
    const {page, limit,} = this.state
    const data = await ClientsAPI.GetClientsData({page, limit}, false);
    this.setState({cliets: data.data.data, total: data.data.data_total_count})
  }

  handleChange = async (event) => {
    const {target} = event;

    if (target.name === 'img') {

      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          image: reader.result,
          imageFile: file,
          imageName: file.name,
        });
      };
      reader.onerror = () => {
        this.setState({image: null, imageFile: null});
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({[target.name]: target.value})

    }
  }

  submit = async (event, errors) => {
    const {address, companyName, image, phoneNumber, city, state, zip} = this.state;

    const data = {
      name: companyName,
      address: address,
      phone: phoneNumber,
      photo: image,
      city,
      state,
      zip,
    }

    if (errors.length === 0) {
      const res = await ClientsAPI.CreateClient(data);
      if (res.status === 200) {
        this.setState({popupOpen: false})
      }

      else {
        this.setState({errorMsg: true})
      }
    }
  }

  openPopup = () => {
    this.setState({popupOpen: true})
  }

  searching = async (value) => {
    let {delayTimer} = this.state

    const paginationSearch = {
      page: 1,
      limit: 12,
      search: value
    }

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {

      if (value.length >= 3) {
        const data = await ClientsAPI.GetSearchClientsData(paginationSearch);
        this.setState({cliets: data.data.data, total: data.data.data_total_count});
      } else {
        const data = await ClientsAPI.GetClientsData({page: 1, limit: 12}, false);
        this.setState({cliets: data.data.data, total: data.data.data_total_count})
      }
    }, 1500);
    this.setState({delayTimer})
  }

  handlePageChange = async (page) => {
    const {limit} = this.state;
    const data = await ClientsAPI.GetClientsData({page: page.selected + 1, limit}, false);
    this.setState({page, cliets: data.data.data});
  }

  refreshState = async() => {
    const {limit,page} = this.state;
    const data = await ClientsAPI.GetClientsData({page: page.selected + 1, limit}, false);
    this.setState({page, cliets: data.data.data});
  }

  render() {

    const {cliets = [], popupOpen, limit, total, errorMsg, imageName} = this.state;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'clients'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Brokers</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item active">Brokers</li>
                    </ol>
                    <div className="main-content-header-search">
                      <input className="form-control" type="search" id="example-text-input"
                             placeholder="Search by company, address..."
                             maxLength="100"
                             onChange={(e) => this.searching(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="main-content-header-right main-content-header-right-stretch">
                    <button className="btn custom-btn" onClick={this.openPopup}>
                      Create New Broker
                    </button>
                    <div className="main-content-header-search-stat">
                      {total} brokers found
                    </div>
                  </div>
                </div>

                <div className="dashboard-content dashboard-content-main">
                  {cliets.map((item) => {
                    return <ClientCard data={item} key={uuidv4()} refreshState={this.refreshState}/>
                  })}
                </div>

                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  marginPagesDisplayed={2}
                  containerClassName={'pagination custom-pagination'}
                  subContainerClassName={'pages pagination '}
                  activeClassName={'active'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item disabled'}
                  breakLinkClassName={'page-link disabled'}
                  onPageChange={this.handlePageChange}
                  pageCount={Math.ceil(total / limit)}
                />

              </div>
            </div>

            {popupOpen ? (
              <div className="modal fade bs-example-modal-lg custom-modal show " tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                   style={{paddingRight: '15px', display: 'block', background: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myLargeModalLabel">Create New Broker</h5>
                      <button type="button" className="close" onClick={() => this.setState({popupOpen: false})}>×</button>
                    </div>
                    <div className="modal-body modal-body-clients">

                      <AvForm onSubmit={this.submit}>
                        <div className="row">
                          <div className="col-12">
                            <AvField
                              name="companyName"
                              label="Company name*"
                              required
                              errorMessage="Invalid copmany name"
                              type="text"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              onChange={(e) => this.handleChange(e)}/>
                          </div>
                          <div className="col-12">
                            <div className="form-group">
                              <div className="custom-file">
                                <p style={{ width: 230 }}>Upload company logo</p>
                                <input
                                  name="img"
                                  type="file"
                                  className="custom-file-input"
                                  id="fileUpload"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={(e) => this.handleChange(e)}/>
                                <label className="btn btn-secondary" htmlFor="fileUpload">Choose file</label>
                                <span className="custom-file-name">{imageName}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <AvField
                              name="phoneNumber"
                              label="Phone number"
                              type="_tel"
                              maxLength="100"
                              onChange={(e) => this.handleChange(e)}/>
                          </div>
                          <div className="col-12">
                            <AvField
                              name="address"
                              label="Address*"
                              required
                              errorMessage="Invalid address name"
                              type="text"
                              maxLength="100"
                              validate={{required: {value: true}}}
                              onChange={(e) => this.handleChange(e)}/>
                          </div>
                        </div>
                        {errorMsg ? <span className="errorMsg">This client already exist or something go wrong</span>:null}
                        <div className="row">
                          <div className="col-12 text-right">
                            <Button className="btn btn-primary w-md custom-btn" type="submit">Save</Button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>) : null}
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Clients);
